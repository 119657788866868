/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { useCart, useWishlist as useWishlistComposable, Product } from '@gemini-vsf/composables';
import { getProductDetailsCommand } from '~/composables/useWishlist/getProductDetailCommand';
import { useAddToCart } from '~/helpers/cart/addToCart';

// @ts-ignore
const useWishlist = (productList?: any) => {
  const { addItem: addProductToCart, error: cartError } = useCart();
  const { showCartError } = useAddToCart();
  const { wishlist, load: loadWishlist, removeItem, addItem, hasItemsInWishlist, updateItem } = useWishlistComposable();
  const context = useContext();

  const itemsInWishlist = sharedRef<Record<string, boolean>>({}, 'useWishlist-itemsInWishlist');

  const filterProductSkus = (products?) => {
    const productsToFilter = products || productList.value;
    return productsToFilter
      .filter((product) => !product.isMoreItem)
      .reduce((acc, product) => {
        acc.push(product.sku);

        if (product?.variants) {
          product.variants.forEach((item) => {
            acc.push(item.product?.sku);
          });
        }

        return acc;
      }, []);
  };

  const pushItems = (items) => {
    itemsInWishlist.value = { ...itemsInWishlist.value, ...items };
  };

  const loadItemsFromList = async (initialProductList) => {
    // todo check if we can use some sort of cache here
    pushItems(await hasItemsInWishlist({ items: filterProductSkus(initialProductList) }));
  };

  const toggleWishlist = async (product: Product) => {
    await (!itemsInWishlist.value[product.sku] ? addItem({ product, quantity: 1 }) : removeItem({ product }));
    pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
  };

  const addItemToCart = async (wishlistItem) => {
    const productGrn = wishlistItem.item_grn;
    const productId = wishlistItem.product?.uid.split('::')?.[1];
    const variant = wishlistItem.product.variants.find((item) => item.product.uid === productGrn);
    const product = await getProductDetailsCommand.execute(context, {
      filter: {
        // @ts-ignore
        uid: productId,
      },
      // @ts-ignore
      configurations: variant.option_uids as string[],
    });
    // @ts-ignore
    await addProductToCart({ product, quantity: wishlistItem.qty });
    if (cartError.value.addItem) {
      showCartError(cartError.value.addItem);
      return;
    }
    // @ts-ignore
    await removeItem({ product: wishlistItem.product });
  };

  return {
    itemsInWishlist,
    loadItemsFromList,
    toggleWishlist,
    removeItemFromWishlist: removeItem,
    addItemToWishlist: addItem,
    addItemToCart,
    wishlist,
    loadWishlist,
    updateItem,
    hasItemsInWishlist,
  };
};

export default useWishlist;
